import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "../../emotion";

export const PageWrapper = styled.div<{ hamburgerOpen?: boolean }>`
  display: flex;
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
  position: relative;

  overflow-y: ${(props) => (props.hamburgerOpen ? "hidden" : "auto")};
  max-height: ${(props) => (props.hamburgerOpen ? "100vh" : "auto")};
`;

export const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  max-width: 100vw;
  width: 100%;
  overflow-x: hidden;
`;

export const NavbarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 65px;
  padding: 0 48px;

  @media ${(props) => props.theme.mq.tablet} {
    padding: 0 16px;
  }
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  overflow-x: hidden;
  width: 1139px;
  height: 617px;

  right: -335px;
  top: -283px;

  z-index: -1;

  background: radial-gradient(
      66.55% 66.55% at 71.2% 35.87%,
      #83c6e4 0%,
      #cae6b8 47.43%,
      #ffba8b 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  opacity: 0.4;
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

export const NavItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > :not(:first-of-type) {
    margin-left: 40px;
  }

  @media ${(props) => props.theme.mq.tablet} {
    display: none;
  }
`;

export const NavItem = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  padding: 24px 8px;

  cursor: pointer;
`;

const mqArr = Object.values(theme.mq).map((bp) => `@media ${bp}`);

export const scrollToTopLocation = css`
  ${mqArr[7]} {
    right: 60px;
  }

  ${mqArr[6]} {
    right: 60px;
  }

  ${mqArr[5]} {
    right: 30px;
  }

  ${mqArr[4]} {
    right: 30px;
  }

  ${mqArr[3]} {
    right: 0;
  }
  ${mqArr[2]} {
    right: 0;
  }

  ${mqArr[1]} {
    right: 0;
  }

  ${mqArr[0]} {
    right: 0;
  }
`;

export const ScrollToTop = styled.button`
  position: fixed;

  padding: 16px 8px;
  background: ${(props) => props.theme.colors.lightOrange};
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(25, 53, 97, 0.1);
  border-radius: 32px;

  border: none;
  ${scrollToTopLocation}
  bottom: 60px;

  cursor: pointer;

  svg {
    color: ${(props) => props.theme.colors.brand};
  }

  &:focus {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 1px 3px rgba(63, 153, 225, 0.6);
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.brand};

    svg {
      color: ${(props) => props.theme.colors.siena};
    }
  }

  @media ${(props) => props.theme.mq.tablet} {
    border-radius: 24px 0 0 24px;
    bottom: 60px;
  }
`;
