import * as React from "react";
import ContactInfoContainer from "./contact-info-container";
import Footer from "./footer";
import { MobileFooterContainer } from "./footer.components";

const MobileFooter: React.FunctionComponent = () => (
  <MobileFooterContainer>
    <ContactInfoContainer />
    <Footer />
  </MobileFooterContainer>
);

export default MobileFooter;
