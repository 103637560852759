import { Field, FieldAttributes, FieldProps } from "formik";
import * as React from "react";
import Input, { InputProps } from "./input";

export type InputFieldProps = Pick<
  InputProps,
  "placeholder" | "type" | "label" | "fullwidth" | "onFocus" | "min" | "max"
> &
  FieldAttributes<unknown>;

const InputField: React.FunctionComponent<InputFieldProps> = ({
  placeholder,
  type,
  label,
  fullwidth,
  onFocus,
  min,
  max,
  ...fieldProps
}) => (
  <Field {...fieldProps}>
    {({ field, meta }: FieldProps) => (
      <>
        <Input
          {...field}
          label={label}
          min={min}
          max={max}
          fullwidth={fullwidth}
          placeholder={placeholder}
          type={type}
          onFocus={onFocus}
          error={meta.touched && meta.error ? meta.error : undefined}
        />
      </>
    )}
  </Field>
);

export default InputField;
