import styled from "@emotion/styled";
import QuotesIllustration from "./quotes.svg";

export const AboutUsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  overflow-y: hidden;
`;

export const AboutUsSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  padding-inline: 48px;
  box-sizing: border-box;

  margin-block: 80px;

  @media ${(props) => props.theme.mq.tablet} {
    flex-direction: column;
    width: 100%;
    margin-block: 32px;

    padding-inline: 16px;
  }
`;

export const Background = styled.div`
  position: absolute;
  width: 1139px;
  height: 617px;
  left: -35%;
  bottom: -45%;

  background: radial-gradient(
      66.55% 66.55% at 71.2% 35.87%,
      #83c6e4 0%,
      #cae6b8 47.43%,
      #ffba8b 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  opacity: 0.6;
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
`;

export const AboutUsTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  margin-left: 32px;
  @media ${(props) => props.theme.mq.tablet} {
    margin-left: 0;
    margin-top: 180px;
  }
`;

export const Quotes = styled(QuotesIllustration)`
  position: absolute;
  left: -42.45px;
  top: 409.77px;

  z-index: -1;

  @media ${(props) => props.theme.mq.tablet} {
    top: -35px;
    left: -30px;
    transform: scale(0.5);
  }
`;

export const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${(props) => props.theme.mq.tablet} {
    position: absolute;
    right: 0;
  }
`;

export const SubHeading = styled.h3`
  font-size: 20px;
  margin-block: 40px;

  @media ${(props) => props.theme.mq.tablet} {
    position: relative;
  }
`;
