import styled from "@emotion/styled";

export const HiddenCheckbox = styled.input`
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  padding: 0;
  position: absolute;
  height: 16px;
  width: 16px;
`;

export const StyledCheckbox = styled.div<{
  isChecked: boolean;
  isError: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 11px;
  width: 11px;
  margin: 2.5px;
  border-radius: 2px;
  border: 1.5px solid ${(props) => props.theme.colors.brand};
  background-color: ${(props) =>
    props.isChecked ? props.theme.colors.brand : "transparent"};

  box-shadow: ${(props) =>
    props.isError
      ? " 0px 0px 0px 1px #FFBA8B, 0px 0px 1px 3px rgba(197, 48, 48, 0.6)"
      : "none"};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
`;

export const CheckboxFieldWrapper = styled.div<{ fullwidth?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
