import styled from "@emotion/styled";

export const AboutTreatmentContainer = styled.div<{ isDiseaseList?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 80px;
  background-color: ${(props) =>
    props.isDiseaseList ? "transparent" : "white"};
  @media ${(props) => props.theme.mq.tablet} {
    padding-top: 32px;
  }
`;

export const AboutTreatment = styled.div<{ isDiseaseList?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  padding-inline: 48px;
  box-sizing: border-box;

  margin-block: ${(props) => (props.isDiseaseList ? 32 : 0)}px;

  @media ${(props) => props.theme.mq.tablet} {
    max-width: auto;
    padding: 0 16px;
    margin-top: 0;
    margin-bottom: ${(props) => (props.isDiseaseList ? 32 : 0)}px;
  }
`;

export const AboutTreatmentHeadingCaption = styled.p<{
  isDiseaseList?: boolean;
}>`
  max-width: 715px;
  margin-top: ${(props) => (props.isDiseaseList ? 16 : 32)}px;
  font-size: 14px;
`;

export const DiseaseListHeading = styled.h3`
  font-size: 18px;
`;

export const DiseaseList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 12px;
  margin-top: 32px;

  @media ${(props) => props.theme.mq.laptop} {
    max-height: 1200px;
    row-gap: 24px;
  }
  @media ${(props) => props.theme.mq.tablet} {
    max-height: 1200px;
    row-gap: 24px;
  }
`;

export const Disease = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.base};
  max-width: 365px;

  @media ${(props) => props.theme.mq.tablet} {
    max-width: 50%;
    font-size: ${(props) => props.theme.fonts.sizes.xxxs};
  }
`;
