/* eslint-disable no-inline-comments */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@packages/ui/emotion";

export enum ButtonSize {
  Large,
  Medium,
  Small,
  ExtraSmall,
}

export enum ButtonVariant {
  Primary,
  Secondary,
}

export interface StyledButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  isButtonWithIcon?: boolean;
}

const getBackgroundColor = (variant?: ButtonVariant): string => {
  switch (variant) {
    case ButtonVariant.Primary:
    default:
      return theme.colors.darkOrange;
    case ButtonVariant.Secondary:
      return theme.colors.navy;
  }
};

const getHoverBackgroundColor = (variant?: ButtonVariant): string => {
  switch (variant) {
    case ButtonVariant.Primary:
      return theme.colors.siena;
    case ButtonVariant.Secondary:
    default:
      return theme.colors.almostBlack;
  }
};

export const ButtonWrapper = styled.button<StyledButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;

  cursor: pointer;

  color: ${(props) => props.theme.colors.gray6};
  border-radius: 24px;
  background-color: ${(props) => getBackgroundColor(props.variant)};

  border: none;
  padding: ${(props) =>
    props.size === ButtonSize.Large ? "12px 32px" : "8px 16px"};

  font-size: ${(props) => (props.size === ButtonSize.Large ? 18 : 16)}px;
  font-weight: ${(props) => (props.size === ButtonSize.Large ? 700 : 500)};
  line-height: 120%;

  &:focus {
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 1px 3px rgba(63, 153, 225, 0.6);
  }
  &:hover {
    background-color: ${(props) => getHoverBackgroundColor(props.variant)};
  }

  svg {
    margin-left: 8px;
  }

  ${(props) =>
    props.size === ButtonSize.ExtraSmall &&
    css`
      font-weight: 500;
      font-size: 14px;
      line-height: 120%;

      padding: 4px 14px;
    `}

  @media ${(props) => props.theme.mq.tablet} {
    padding: 8px 16px;

    font-size: ${(props) => (props.size === ButtonSize.Large ? 18 : 14)}px;
    font-weight: 500;
  }
`;
