import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Form } from "formik";

export const QuestionnaireForm = styled(Form)<{ submitted?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${(props) => props.theme.colors.lightOrange};
  border-radius: 16px;

  position: relative;

  & > :not(:last-child) {
    margin-top: 16px;
  }
  & > :last-child {
    margin-top: 24px;
  }

  ${(props) =>
    props.submitted &&
    css`
      & > * {
        z-index: -1;
      }
    `}

  @media ${(props) => props.theme.mq.nonMobile} {
    width: 576px !important;
    min-height: 300px;
    margin-left: 28px;
    padding: 32px 40px;
  }
  @media ${(props) => props.theme.mq.tablet} {
    padding: 16px;
    margin: 32px 16px 0;
    min-height: 300px;
  }
`;

export const QuestionnaireRow = styled.div`
  display: flex;
  flex-direction: row;

  & > :first-of-type {
    margin-right: 24px;
  }

  @media ${(props) => props.theme.mq.tablet} {
    flex-direction: column;
    & > :first-of-type {
      margin-right: 0;
    }
    & > :nth-of-type(2) {
      margin-top: 16px;
    }
  }
`;

export const QuestionnaireRowAddress = styled.div`
  display: flex;
  flex-direction: row;

  & > :first-of-type {
    flex: 1;
    margin-right: 24px;
  }
  & > :nth-of-type(2) {
    flex: 0;
  }
`;

export const CheckboxLabel = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
`;

export const CheckboxLink = styled.a`
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

export const ThankYou = styled.h3<{ submitted: boolean }>`
  position: absolute;
  width: 100%;
  top: calc(50% - 30px);
  left: 0;
  right: 10;
  text-align: center;

  z-index: ${(props) => (props.submitted ? 1 : -1)};
`;
