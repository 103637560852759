import { useTheme } from "@emotion/react";
import { useField } from "formik";
import * as React from "react";
import Button, { ButtonSize } from "../button";
import { InputLabel, InputWrapper } from "../input-field/input.components";
import { CrossIcon, FileName, HiddenFileInput } from "./file-input.components";

interface Props {
  label?: string;
}

const FileInput: React.FunctionComponent<
  React.ComponentPropsWithoutRef<"input"> & Props
> = ({ label }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField<File[]>("files");
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const theme = useTheme();

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e?.target?.files) {
        let size = 0;
        [...field.value, ...Array.from(e.target.files)].forEach(
          (file) => (size += file.size),
        );

        const sizeMb = size / 1000000;
        console.log(sizeMb);
        if (sizeMb > 4.2) {
          helpers.setError(
            "Rozmiar załączników przekracza 4.2MB. Skompresuj pliki lub załącz je w oddzielnej wiadomości na konopnymed@gmail.com",
          );
        } else {
          helpers.setValue(e?.target?.files ? Array.from(e.target.files) : []);
        }
      }
    },
    [field.value, helpers],
  );

  return (
    <InputWrapper>
      <InputLabel>{label}</InputLabel>
      <HiddenFileInput
        onChange={onChange}
        type="file"
        ref={fileInputRef}
        accept=".pdf, image/*, .zip"
        multiple
      />
      <Button
        type="button"
        size={ButtonSize.ExtraSmall}
        onClick={() => fileInputRef?.current?.click()}
      >
        Wybierz pliki
      </Button>
      {field.value?.map((file: File, i: number) => (
        <FileName key={i}>
          {file.name}
          <CrossIcon
            onClick={() =>
              helpers.setValue(
                field.value?.filter((f: File) => f !== file) ?? null,
              )
            }
          />
        </FileName>
      ))}
      {meta?.error && (
        <p
          style={{
            color: theme.colors.red,
            fontSize: "12px",
            marginTop: "8px",
          }}
        >
          {meta?.error}
        </p>
      )}
    </InputWrapper>
  );
};

export default FileInput;
