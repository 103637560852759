import * as React from "react";
import { Divider } from "../../shared/divider";
import {
  AboutTreatment,
  AboutTreatmentContainer,
  AboutTreatmentHeadingCaption,
  Disease,
  DiseaseList,
  DiseaseListHeading,
} from "./about-treatment-section.components";
import { diseaseList } from "./disease-list";

const AboutTreatmentSection: React.FunctionComponent<
  Record<string, React.RefObject<HTMLDivElement>>
> = React.forwardRef<
  HTMLDivElement,
  Record<string, React.RefObject<HTMLDivElement>>
>((_, ref) => {
  return (
    <>
      <AboutTreatmentContainer ref={ref}>
        <AboutTreatment>
          <h1>O leczeniu Medyczną Marihuaną</h1>
          <AboutTreatmentHeadingCaption>
            Z leczniczych właściwości marihuany ludzie korzystali już kilka
            tysięcy lat temu m.in. w Indiach i Chinach. Już wtedy zdawali sobie
            sprawę z nieocenionych właściwości marihuany w walce z bezsennością,
            bólem czy problemami żołądkowo-jelitowymi. Jednak lista poważnych
            schorzeń, które leczy marihuana, jest dość pokaźna.
          </AboutTreatmentHeadingCaption>
          <Divider marginBlock={32} />
        </AboutTreatment>
      </AboutTreatmentContainer>
      <AboutTreatmentContainer isDiseaseList>
        <AboutTreatment isDiseaseList>
          <DiseaseListHeading>
            Dziś medyczną marihuanę zaleca się w następujących przypadkach:
          </DiseaseListHeading>
          <DiseaseList>
            {diseaseList.map((disease) => (
              <Disease key={disease}>• {disease}</Disease>
            ))}
          </DiseaseList>
          <Divider marginBlock={32} />
          <DiseaseListHeading>
            Jakie są przeciwwskazania do stosowania medycznej marihuany?
          </DiseaseListHeading>
          <AboutTreatmentHeadingCaption isDiseaseList>
            Stosowanie marihuany medycznej jest przeciwwskazane w przypadku
            nadwrażliwości na kannabinoidy. Nie zaleca się też stosowania konopi
            medycznych u osób poniżej 25. roku życia. Uważa się, że głównie THC
            wywiera niekorzystny wpływ na psychikę w okresie dojrzewania, tj.
            pomiędzy 10., a 18-24. rokiem życia (choć i tutaj zdania naukowców
            są podzielone). Ponadto, przy niektórych zaburzeniach psychicznych
            (ostre psychozy, schizofrenia) należy zachować szczególną ostrożność
            i dawkę dostosowuje indywidualnie tylko lekarz - specjalista.
            Medycznej marihuany nie powinny stosować kobiety w ciąży oraz
            karmiące i osoby cierpiące na poważne schorzenia układu krążenia.
          </AboutTreatmentHeadingCaption>
        </AboutTreatment>
      </AboutTreatmentContainer>
    </>
  );
});

export default AboutTreatmentSection;
