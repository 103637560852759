import styled from "@emotion/styled";

export const Divider = styled.hr<{ marginBlock: number }>`
  height: 2px;
  width: 32px;
  border-radius: 32px;

  border: none;

  background-color: ${(props) => props.theme.colors.brand};
  margin: ${(props) => props.marginBlock}px 0;
`;
