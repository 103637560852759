import styled from "@emotion/styled";

export const FaqSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${(props) => props.theme.colors.brand};
`;

export const FaqSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  width: 100%;
  padding-inline: 48px;
  box-sizing: border-box;

  margin-block: 80px;

  @media ${(props) => props.theme.mq.tablet} {
    width: 100%;
    margin-block: 32px;

    padding-inline: 16px;
  }
`;

export const FaqColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 40px;

  @media ${(props) => props.theme.mq.tablet} {
    flex-direction: column;
  }
`;

export const FaqTilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  &:first-of-type {
    margin-right: 56px;
  }
`;

export const CollapsibleFaqHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;

    color: ${(props) => props.theme.colors.navy};
    margin-right: 24px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const FaqTile = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-content: start;

  padding: 24px 32px;
  border-radius: 32px;

  background-color: ${(props) => props.theme.colors.lightOrange};

  &:not(:first-of-type) {
    margin-top: 40px;
  }
`;

export const QuestionAnswer = styled.p`
  font-size: 16px;

  margin-top: 24px;

  &:first-of-type {
    margin-top: 16px;
  }
`;
