import * as React from "react";
import useIsSmallDevice from "../../../utils/hooks/use-is-small-device";
import CostSectionBadges from "./cost-section-badges";
import {
  CostSectionContainer,
  CostSectionDescription,
  CostSectionItemsWrapper,
  CostSectionTextWrapper,
  CostSectionWrapper,
} from "./cost-section.components";

interface Props {
  ref?: React.Ref<HTMLDivElement>;
  children?: React.ReactNode;
}

const CostSection: React.FunctionComponent<Props> = React.forwardRef<
  HTMLDivElement,
  Props
>(({ children }, ref) => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <CostSectionContainer ref={ref}>
      <CostSectionWrapper>
        <CostSectionItemsWrapper>
          <CostSectionTextWrapper>
            <h1>Ile to kosztuje?</h1>
            {isSmallDevice && <CostSectionBadges />}
            <CostSectionDescription>
              KonopnyMed zaprasza na wizyty stacjonarne! Od teraz możesz spotkać
              się osobiście z naszymi specjalistami i uzyskać profesjonalna
              pomoc bezpośrednio w naszej klinice.
              <br />
              <br /> Chcesz zostać pacjentem terapii medyczna marihuana, ale nie
              wiesz, jak zacząć? Obawiasz się, że proces kwalifikacji lub
              przeniesienia do naszej kliniki będzie skomplikowany?
              <br />
              <br /> Nic bardziej mylnego! Jesteśmy tu, by ułatwić Ci ten krok!
              Umówienie się na wizytę jest szybkie i proste. Po wypełnieniu
              krótkiego kwestionariusza nasi specjaliści skontaktują się z Toba,
              aby umówić Cie na osobista wizytę w naszym gabinecie, zbadać Twoje
              potrzeby i zaproponować najlepsza formę terapii. Przyjdź do nas,
              porozmawiaj bezpośrednio z naszym lekarzem i uzyskaj receptę od
              reki.
            </CostSectionDescription>
            {children}
          </CostSectionTextWrapper>
          {!isSmallDevice && <CostSectionBadges />}
        </CostSectionItemsWrapper>
      </CostSectionWrapper>
    </CostSectionContainer>
  );
});

export default CostSection;
