export const diseaseList = [
  "ADHD",
  "alkoholizmu i innych uzależnień",
  "chorobie Alzheimera",
  "anoreksji",
  "astmy",
  "autyzmu",
  "bezsenności i zaburzeń snu",
  "boreliozy",
  "choroby Leśniowskiego-Crohna",
  "choroby Parkinsona",
  "choroby afektywnej-dwubiegunowej",
  "chorób autoimmunologicznych",
  "chorób zapalnych jelit",
  "cukrzycy",
  "depresji",
  "dystonii",
  "fibromialgii",
  "HIV/AIDS",
  "jaskry",
  "lęków",
  "miastenii",
  "migreny",
  "neuralgii",
  "nowotworów",
  "osteoporozy",
  "padaczki",
  "pląsawicy Huntingtona",
  "przewlekłego bólu o różnym pochodzeniu",
  "reumatoidalnego zapalenia stawów",
  "spastyczności mięśni",
  "stwardnienia rozsianego",
  "urazów rdzenia kręgowego",
  "WZW typu C",
  "zaburzeń obsesyjno-kompulsywnych (OCD)",
  "zaburzeń żołądkowo-jelitowych",
  "zapalenia mięśni i stawów",
  "łuszczycowego zapalenia stawów",
  "zespołu Ehlersa-Danlosa",
  "zespołu Sjorgena",
  "zespołu Touretta",
  "zespołu jelita wrażliwego",
  "zespołu przewlekłego zmęczenia",
  "zespołu stresu pourazowego",
  "zwyrodnienia stawów",
];
