import * as React from "react";
import {
  CallUsContainer,
  Email,
  InfoContainer,
  Open,
  PhoneNumber,
} from "./footer.components";
import Phone from "./phone.svg";

const ContactInfoContainer: React.FunctionComponent = () => (
  <InfoContainer>
    <CallUsContainer href="tel:+48791265907">
      <Phone />
      <PhoneNumber>791-265-907</PhoneNumber>
    </CallUsContainer>
    <Email>konopnymed@gmail.com</Email>
    <Open>7 dni w tygodniu, od 8:00 do 19:00</Open>
  </InfoContainer>
);

export default ContactInfoContainer;
