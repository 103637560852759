import styled from "@emotion/styled";

export const StepsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 120px 0 120px;
  background-color: white;
  width: 100%;
  @media ${(props) => props.theme.mq.tablet} {
    max-width: auto;
    padding: 80px 0 0 0;
  }
`;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 32px;
  max-width: 1440px;
  width: 100%;
  padding-inline: 48px;
  box-sizing: border-box;

  @media ${(props) => props.theme.mq.tablet} {
    max-width: auto;
    padding: 0 16px;
    flex-direction: column;
  }
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  flex: 1;
  background-color: ${(props) => props.theme.colors.bg};

  &:first-of-type {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    background-color: ${(props) => props.theme.colors.brand};

    position: relative;

    :after {
      content: "";
      position: absolute;
      right: -9.33px;
      top: calc(50% - 16px);
      transform: rotate(-45deg);

      width: 32px;
      height: 32px;
      border-radius: 8px;

      background-color: ${(props) => props.theme.colors.brand};
    }
  }
  &:last-child {
    border-top-right-radius: 32px;
    border-bottom-right-radius: 32px;
  }

  @media ${(props) => props.theme.mq.tablet} {
    padding: 24px 16px;

    &:first-of-type {
      border-top-right-radius: 32px;
      border-top-right-radius: 32px;
      border-bottom-left-radius: 0;

      :after {
        right: calc(50% - 12px);
        top: calc(100% - 12px);
        transform: rotate(-135deg);

        width: 24px;
        height: 24px;
        border-radius: 6px;
        border-bottom-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &:last-child {
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;
      border-top-right-radius: 0;
    }
    p {
      font-size: ${(props) => props.theme.fonts.sizes.xxxs};
    }
  }
`;

export const StepHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 16px;

  @media ${(props) => props.theme.mq.tablet} {
    margin-bottom: 8px;

    h4 {
      font-size: ${(props) => props.theme.fonts.sizes.xxs};
    }
  }
`;

export const HeadingContainer = styled.div`
  text-align: center;
`;

export const StepCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 16px;
  margin-right: 16px;

  background-color: ${(props) => props.theme.colors.lightOrange};

  @media ${(props) => props.theme.mq.tablet} {
    width: 32px;
    height: 32px;
    border-radius: 12px;

    svg {
      transform: scale(0.8);
    }
  }
`;

export const HeadingCaption = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.base};
  margin-top: 8px;
`;
