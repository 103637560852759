import * as React from "react";
import { Collapsible } from "../../shared/collapsible/collapsible";
import Caret from "./caret-down.svg";
import { faqElements } from "./faq-elements";
import {
  CollapsibleFaqHeader,
  FaqTile,
  QuestionAnswer,
} from "./faq-section.components";

const FaqTilesMobile: React.FunctionComponent = () => (
  <>
    {faqElements.flat().map((element, index) => (
      <FaqTile key={`faq-tiles-${index}`}>
        <Collapsible
          triggerWhenOpen={
            <CollapsibleFaqHeader>
              <h3> {element.question}</h3>
              <div style={{ transform: "rotate(180deg)" }}>
                <Caret />
              </div>
            </CollapsibleFaqHeader>
          }
          trigger={
            <CollapsibleFaqHeader>
              <h3> {element.question}</h3>
              <div>
                <Caret />
              </div>
            </CollapsibleFaqHeader>
          }
        >
          {element.answer.map((answerPart, answerIndex) => (
            <QuestionAnswer key={`faq-tiles-${index}-answer-${answerIndex}`}>
              {answerPart}
            </QuestionAnswer>
          ))}
        </Collapsible>
      </FaqTile>
    ))}
  </>
);

export default FaqTilesMobile;
