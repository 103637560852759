import styled from "@emotion/styled";
import Cross from "./cross.svg";

export const HiddenFileInput = styled.input`
  display: none;
`;

export const FileName = styled.p`
  margin-top: 6px;

  font-weight: 500;
  font-size: 11px;
  line-height: 14px;

  &:not(:first-of-type) {
  }
`;

export const CrossIcon = styled(Cross)`
  color: ${(props) => props.theme.colors.red};
  margin-left: 4px;
  cursor: pointer;
`;
