import { theme } from "@packages/ui/emotion";
import { useField } from "formik";
import * as React from "react";
import { ErrorMessage, InputLabel } from "../input-field/input.components";
import {
  DropdownIcon,
  SelectDropdown,
  SelectFieldWrapper,
  SelectWrapper,
} from "./select.components";

interface Props {
  fieldName: string;
  options: string[];
  label?: string;
}

const SelectField: React.FC<Props> = ({ fieldName, options, label }) => {
  const [field, meta] = useField(fieldName);

  return (
    <SelectFieldWrapper isError={!!meta.error}>
      {label && <InputLabel>{label}</InputLabel>}
      <SelectWrapper>
        <SelectDropdown {...field} isError={!!meta.error} as="select">
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </SelectDropdown>
        <DropdownIcon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={theme.colors.almostBlack}
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m19.5 8.25-7.5 7.5-7.5-7.5"
            />
          </svg>
        </DropdownIcon>
      </SelectWrapper>
      {meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </SelectFieldWrapper>
  );
};

export default SelectField;
