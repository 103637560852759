import { useField } from "formik";
import * as React from "react";
import Button, { ButtonSize } from "../button";
import { ErrorMessage, InputLabel } from "../input-field/input.components";
import {
  SwitchFieldWrapper,
  SwitchOption,
  SwitchWrapper,
} from "./switch.components";

interface Props {
  fieldName: string;
  options: string[];
  label?: string;
}

const SwitchField: React.FunctionComponent<Props> = ({
  options,
  fieldName,
  label,
}) => {
  const [field, meta, helpers] = useField(fieldName);

  return (
    <SwitchFieldWrapper isError={!!meta.error}>
      <InputLabel>{label}</InputLabel>
      <SwitchWrapper>
        {options.map((option) =>
          field.value === option ? (
            <Button key={option} size={ButtonSize.ExtraSmall}>
              {option}
            </Button>
          ) : (
            <SwitchOption key={option} onClick={() => helpers.setValue(option)}>
              {option}
            </SwitchOption>
          ),
        )}
      </SwitchWrapper>
      {meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </SwitchFieldWrapper>
  );
};

export default SwitchField;
