import * as React from "react";
import LogoMobile from "../../../public/images/logo-mobile.svg";
import Button, { ButtonSize, ButtonVariant } from "../../shared/button";
import { Divider } from "../../shared/divider";
import CloseIcon from "./close-menu.svg";
import Facebook from "./facebook.svg";
import Instagram from "./instagram.svg";
import {
  HamburgerFooter,
  HamburgerWrapper,
  MobileNavbar,
  MobileNavItem,
  SocialInfo,
} from "./mobile-navigation.components";
import Phone from "./phone.svg";

interface Props {
  sections: string[];
  scrollToSection: (_: number) => void;
  scrollToQuestionnaire: () => void;
  close: () => void;
}

const MobileNavigation: React.FunctionComponent<Props> = ({
  sections,
  scrollToSection,
  scrollToQuestionnaire,
  close,
}) => (
  <HamburgerWrapper>
    <MobileNavbar>
      <div>
        <CloseIcon onClick={close} />
        <LogoMobile />
      </div>
      <Button
        onClick={scrollToQuestionnaire}
        size={ButtonSize.Small}
        variant={ButtonVariant.Secondary}
      >
        Kwestionariusz
      </Button>
    </MobileNavbar>
    {sections.map((s, i) => (
      <MobileNavItem onClick={() => scrollToSection(i)}>{s}</MobileNavItem>
    ))}
    <Divider marginBlock={32} />
    <HamburgerFooter>
      <SocialInfo
        onClick={() => window.open("https://www.facebook.com/konopnymed/")}
      >
        <Facebook />
        <h3>konopnyfarmaceuta</h3>
      </SocialInfo>
      <SocialInfo
        onClick={() => window.open("https://www.instagram.com/konopnymed/")}
      >
        <Instagram />
        <h3>konopnyfarmaceuta</h3>
      </SocialInfo>
      <SocialInfo href="tel:+48791265907">
        <Phone />
        <h3>791-265-907</h3>
      </SocialInfo>
    </HamburgerFooter>
  </HamburgerWrapper>
);

export default MobileNavigation;
