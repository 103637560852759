import * as React from "react";
import {
  CostSectionBadge,
  CostSectionBadgesWrapper,
  CostSectionBadgeTextWrapper,
  CostSectionHeading,
  IconWrapper,
} from "./cost-section.components";
import Hand from "./hand.svg";
import Phone from "./phone.svg";

const CostSectionBadges: React.FunctionComponent = () => {
  const badges = [
    {
      price: "200zł",
      description: "Koszt pierwszej wizyty,",
      description2: "czyli kwalifikacji pacjenta",

      icon: <Phone />,
    },
    {
      price: "150zł",
      description: "Kontynuacja leczenia i",
      description2: "przeniesienie z innej poradni",
      icon: <Hand />,
    },
  ];

  return (
    <CostSectionBadgesWrapper>
      {badges.map((badge) => (
        <CostSectionBadge key={badge.price}>
          <IconWrapper>{badge.icon}</IconWrapper>
          <CostSectionBadgeTextWrapper>
            <CostSectionHeading>{badge.price}</CostSectionHeading>
            <h3>{badge.description}</h3>
            <h3>{badge.description2}</h3>
          </CostSectionBadgeTextWrapper>
        </CostSectionBadge>
      ))}
    </CostSectionBadgesWrapper>
  );
};

export default CostSectionBadges;
