import * as React from "react";
import {
  ErrorMessage,
  InputLabel,
  InputWrapper,
  StyledInput,
} from "./input.components";

export interface InputProps extends React.ComponentPropsWithoutRef<"input"> {
  error?: string;
  label?: string;
  type?: string;
  onFocus?: () => void;
  fullwidth?: boolean;
}

export const Input: React.FunctionComponent<
  InputProps & {
    forwardedRef?: React.Ref<HTMLInputElement>;
  }
> = ({
  error,
  fullwidth,
  type = "text",
  forwardedRef,
  onFocus,
  ...inputProps
}) => (
  <InputWrapper fullwidth={fullwidth}>
    <InputLabel>{inputProps.label}</InputLabel>
    <StyledInput
      {...inputProps}
      ref={forwardedRef}
      isError={!!error}
      type={type}
      fullwidth={fullwidth}
      onFocus={onFocus}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </InputWrapper>
);

export default React.forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Input {...props} forwardedRef={ref} />
));
