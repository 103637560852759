import * as React from "react";
import { Divider } from "../../shared/divider";
import One from "./one.svg";
import {
  HeadingCaption,
  HeadingContainer,
  Step,
  StepCount,
  StepHeading,
  StepsContainer,
  StepsSectionContainer,
} from "./steps-section.components";
import Three from "./three.svg";
import Two from "./two.svg";

const StepsSection: React.FunctionComponent = () => {
  const steps = [
    {
      countIcon: <One />,
      name: "Wypełnij kwestionariusz",
      description:
        "Wypełnij kwestionariusz dostępny na naszej stronie; te dane są nam potrzebne do dalszej rejestracji.",
    },
    {
      countIcon: <Two />,
      name: "Specjalista skontaktuje się z Tobą",
      description:
        "Po wypełnieniu kwestionariusza, nasi specjaliści niezwłocznie skontaktują się z Tobą by umówić się na wizytę w wybranym przez ciebie mieście.",
    },
    {
      countIcon: <Three />,
      name: "Otrzymasz receptę",
      description:
        "Podczas konsultacji z lekarzem otrzymasz receptę na medyczną marihuanę oraz zostaniesz poinformowany o planie leczenia.",
    },
  ];

  return (
    <StepsSectionContainer>
      <HeadingContainer>
        <h1>Jesteśmy tu, aby Ci pomóc</h1>
        <HeadingCaption>
          Z nami załatwisz wszelkie formalności w miłej i przyjemnej atmosferze
        </HeadingCaption>
      </HeadingContainer>
      <Divider marginBlock={32} />
      <StepsContainer>
        {steps.map((step) => (
          <Step key={step.name}>
            <StepHeading>
              <StepCount>{step.countIcon}</StepCount>
              <h4>{step.name}</h4>
            </StepHeading>
            <p>{step.description}</p>
          </Step>
        ))}
      </StepsContainer>
    </StepsSectionContainer>
  );
};

export default StepsSection;
