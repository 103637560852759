import * as React from "react";
import useIsSmallDevice from "../../../utils/hooks/use-is-small-device";
import { Divider } from "../../shared/divider";
import {
  AboutUsSectionContainer,
  AboutUsSectionWrapper,
  AboutUsTextContainer,
  Background,
  IllustrationWrapper,
  Quotes,
  SubHeading,
} from "./about-us-section.components";
import IllustrationMobile from "./illustration-mobile.svg";
import Illustration from "./illustration.svg";

const AboutUsSection: React.FunctionComponent<
  Record<string, React.RefObject<HTMLDivElement>>
> = React.forwardRef<
  HTMLDivElement,
  Record<string, React.RefObject<HTMLDivElement>>
>((_, ref) => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <AboutUsSectionContainer ref={ref}>
      {!isSmallDevice && <Background />}
      <AboutUsSectionWrapper>
        <IllustrationWrapper>
          {isSmallDevice ? <IllustrationMobile /> : <Illustration />}
        </IllustrationWrapper>
        <AboutUsTextContainer>
          <h1>O nas</h1>
          <Divider marginBlock={32} />
          <p>
            Poradnia KonopnyMed to centrum medyczne składające się z zespołu
            lekarzy, farmaceutów oraz specjalistów w zakresie terapii medyczną
            marihuaną, która dedykowana jest pacjentom zmagającymi się z
            najróżniejszymi schorzeniami. Wieloletnie doświadczenie w terapii
            medyczną marihuaną, współpraca z najlepszymi lekarzami oraz
            indywidualne podejście, są podstawą zadowolenia naszych Pacjentów.
            <br />
            <br />
            Działalność poradni to również prace i badania naukowe nad
            innowacyjnymi i skutecznymi metodami leczenia. Dzięki naszej pracy i
            zaangażowaniu, możemy pomagać coraz większej liczbie pacjentów i
            kierować ofertę nie tylko do tych, którzy wyczerpali konwencjonalne
            możliwości leczenia, ale też do tych, którzy pragną leczyć się tylko
            tą naturalną metodą. Terapia jest ustalana dla każdego indywidualnie
            i polega na przyjmowaniu substancji aktywnych konopi w formie suszu,
            olejku, maści, kremów transdermalnych, a także czopków.
            <br />
            <br /> Oferta jest więc skierowana do Pacjentów, którzy leczą się
            przewlekle, w sposób konwencjonalny, lecz chcieliby to zmienić, gdyż
            uważają, że terapia medyczną marihuaną będzie dla nich
            odpowiedniejsza. Teraz jest taka możliwość! A jeśli jesteś już
            pacjentem MM, to na podstawie dostarczonej dokumentacji medycznej,
            masz możliwość kontynuacji leczenia bez konieczności wizyty w
            gabinecie lekarskim. Ważne jest, aby przed pierwszą teleporadą,
            Pacjent przygotował listę aktualnie przyjmowanych leków oraz
            historię dotychczasowego leczenia.
          </p>
          <SubHeading>
            {isSmallDevice && <Quotes />}
            Wasze zdrowie i bezpieczeństwo są dla nas najważniejsze, a naszą
            misją jest świadczenie usług najwyższej jakości oraz dzielenie się
            wiedzą medyczną i praktyczną.
          </SubHeading>
          <p>
            W terapii wykorzystujemy najczęściej połączenie konopi (z użyciem
            surowca Cannabis Flos) zawierających THC oraz innych preparatów
            konopnych, pozbawionych działania psychoaktywnego, lecz o istotnym
            działaniu leczniczym. Medyczna marihuana jest na ogół bardzo dobrze
            tolerowana, jednak w przypadku, gdy konieczne jest jej stosowanie, a
            pacjent nie życzy sobie odczuwać działania psychoaktywnego, możliwe
            jest również takie dostosowanie dawki leku.
          </p>
          <br />
          <p>
            KonopnyMed Sp. Z o.o. | ul. 3-go Maja 87a, 23-400 Biłgoraj
            <br />
            Biuro ul. Misjonarzy Oblatów 8, 40-129 Katowice
            <br />
            nr KRS 0000997754 | Sąd Rejonowy Lublin Wschód w Lublinie
            <br />
            NIP 918-218-20-68 | REGON 523421863
            <br />
            <b>
              Podmiot wykonujący działalność leczniczą pod numerem księgi
              000000256607
            </b>
            <br />
            Nr konta PKO KonopnyMed: <b>54 1020 1068 0000 1702 0399 0793</b>
          </p>
          {!isSmallDevice && <Quotes />}
        </AboutUsTextContainer>
      </AboutUsSectionWrapper>
    </AboutUsSectionContainer>
  );
});

export default AboutUsSection;
