import * as React from "react";
import Button from "../../../shared/button";
import { TermsWrapper, Text } from "./terms.components";

interface Props {
  close: () => void;
}

const Banner: React.FunctionComponent<Props> = ({ close }) => {
  return (
    <TermsWrapper>
      <Text>
        Szanowni Pacjenci,
        <br />
        <br />
        Uprzejmie informujemy, że od dnia 7 listopada 2024 roku wszystkie
        konsultacje lekarskie realizowane przez KonopnyMed odbywają się
        wyłącznie w formie wizyt stacjonarnych. Zachęcamy do wypełnienia
        kwestionariusza, wyboru lokalizacji oraz umówienia się na wizytę w
        dogodnym dla Państwa mieście.
        <br />
        <br />
        Do końca 2024 roku KonopnyMed będzie obecny w każdym mieście
        wojewódzkim, aby umożliwić Państwu łatwiejszy dostęp do naszych usług
        medycznych.
        <br />
        <br />W przypadku pytań prosimy o kontakt telefoniczny pod numerem
        791265907 lub wiadomość e-mail na adres{" "}
        <a href="konopnymed@gmail.com">konopnymed@gmail.com</a>.
        <br />
        <br />
        Dziękujemy za zaufanie i czekamy na możliwość spotkania w Państwa
        mieście.
        <br />
        <br />Z poważaniem, <br /> mgr farm. Bartłomiej Zalewa <br /> CEO,
        KonopnyMed
      </Text>

      <Button onClick={close}>Zamknij</Button>
    </TermsWrapper>
  );
};

export default Banner;
