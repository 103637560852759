import styled from "@emotion/styled";

export const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin: 80px;
  z-index: 100;

  position: fixed;
  top: 10px;

  background-color: ${(props) => props.theme.colors.bg};
  border-radius: 16px;
  padding: 32px 40px;

  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -1px rgba(25, 53, 97, 0.1);

  > :last-child {
    margin-top: 16px;
  }

  @media ${(props) => props.theme.mq.tablet} {
    padding: 8px 12px;
    margin: 20px;
    bottom: 10;
    overflow-y: scroll;
    max-height: 80%;
    font-size: 12px;
  }
`;

export const Text = styled.p`
  font-size: 16px;

  @media ${(props) => props.theme.mq.tablet} {
    font-size: 18px;
  }
`;
