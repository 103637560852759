import styled from "@emotion/styled";

export const MobileFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  padding: 16px 16px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 72px;
  padding: 16px 48px;

  @media ${(props) => props.theme.mq.tablet} {
    width: 100%;
    padding: 0;
    height: 40px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.mq.tablet} {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const CallUsContainer = styled.a`
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;

export const Email = styled.h3`
  margin-right: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
`;

export const PhoneNumber = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  white-space: nowrap;
  word-break: keep-all;

  margin-left: 8px;
`;

export const Open = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  white-space: nowrap;
  word-break: keep-all;

  @media ${(props) => props.theme.mq.tablet} {
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
  }
`;

export const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 96px;

  & > * {
    cursor: pointer;
  }
`;
