export const faqElements = [
  [
    {
      question: "Czym jest Medyczna Marihuana?",
      answer: [
        "Medyczna Marihuana to żeńskie kwiatostany ziela konopi – Cannabis Sativa L., które po wysuszeniu stanowią surowiec medyczny, mogący być spożywany bezpośrednio w formie palenia, waporyzacji lub doustnie. Możliwa jest też dalsza obróbka surowca do formy oleju konopnego lub kapsułek.",
        "Medyczna marihuana to nic innego jak wysuszone kwiatostany żeńskie najwyższej jakości i pochodzące z certyfikowanych, kontrolowanych upraw. Kwiatostany takie posiadają standaryzowaną zawartość substancji aktywnych i są klasyfikowane jako surowiec farmaceutyczny do wytwarzania leków recepturowych.",
        "Marihuana działa leczniczo dzięki zawartości substancji aktywnych – kannabinoidów, które wpływają na receptory kannabinoidowe – CB1 oraz CB2 (układ endokannabinoidowy) na równi z układem nerwowym i hormonalnym człowieka, odpowiadającymi za utrzymanie równowagi ludzkiego organizmu.",
        "Obecnie, na świecie prowadzonych jest ponad 400 badań klinicznych, a dotychczasowe potwierdziły skuteczność konopi medycznych w leczeniu praktycznie wszelkich schorzeń.",
      ],
    },
    {
      question: "Czym się różni medyczna marihuana od rekreacyjnej?",
      answer: [
        "Produkcja medycznej marihuany odbywa się w ściśle kontrolowanych warunkach, a susz przechodzi szereg restrykcyjnych badań i kontroli – zupełnie tak jak inne leki. Zanim trafi do aptek, jest rzetelnie przebadany na zawartość substancji czynnych, które procentowo opisane są na etykiecie produktu. Dzięki tym surowym wymogom, lekarze mogą wybrać dla pacjenta susz konopi medycznych o określonej procentowo zawartości THC i CBD oraz innych substancji jakie zawiera w swym składzie.",
        "Marihuana sprzedawana na czarnym rynku jest nieznanego pochodzenia, a jej jakość ciężka do przewidzenia. Nie przechodzi żadnych badań i często ulega skażeniu chemikaliami i pestycydami w wyniku nieuczciwych praktyk stosowanych w narkobiznesie dyktowanym przez zapotrzebowanie rynku.",
        // eslint-disable-next-line quotes
        'Ryzyko przyjmowania takich toksyn przez organizm jest ogromnym i nieprzewidywalnym obciążeniem. Dla pacjentów będących w terapii wielu chorób, niekontrolowane stężenie THC oraz zawartość "wzmacniaczy" mogą być bardzo szkodliwe. Dodajmy, iż posiadanie marihuany rekreacyjnej w świetle polskiego prawa jest nielegalne.',
      ],
    },
    {
      question: "Czy wszystkie odmiany konopi dają ten sam efekt?",
      answer: [
        "Nie. Skutki odczuwalne po zastosowaniu konopi są odmienne w zależności od ich rodzaju. Wyróżniamy trzy rodzaje szczepów, a mianowicie: szczepy Indica, które charakteryzują się działaniem uspokajającym i nasennym, redukując stres i dają odprężenie; szczepy Sativa, to rośliny o właściwościach energetyzujących, które wzmagają kreatywność i stymulują do działania oraz i szczepy hybrydowe będące genetyczną mieszanką obu powyższych gatunków.",
      ],
    },
    {
      question: "Jakie są koszty leczenia medyczną marihuaną w Polsce?",
      answer: [
        "Całkowity koszt terapii zależy od wielkości dawek oraz częstotliwości ich przyjmowania. Cena medycznej marihuany w aptece – waha się w przedziale między 260 do 650 zł w zależności od producenta i wielkości opakowania oraz apteki, przy czym 1 gram suszu to koszt około 40-60 zł. W tym momencie leczenie medyczną marihuaną nie jest objęte refundacją NFZ.",
      ],
    },
  ],
  [
    {
      question: "Czy to jest legalne?",
      answer: [
        "W Polsce medyczna marihuana jest legalna od 1 listopada 2017 roku. W praktyce jednak, stosowanie medycznej marihuany budzi nadal wiele kontrowersji i pomimo tego, że została ona zalegalizowana, większa część lekarzy nie chce przepisywać jej pacjentom. Warunkiem zakupu MM jest uzyskanie recepty, którą teoretycznie może wypisać każdy lekarz, za wyjątkiem lekarza weterynarii. W związku z ustawą, na tę chwilę marihuana musi być sprowadzana z zagranicy. Obecnie, w Polsce jedynymi importerami konopi medycznych jest Aurora Deutschland GMBH oraz Spectrum Therapeutics. To właśnie tymi produktami leczą nasi lekarze. W niedalekiej przyszłości mają pojawić się nowe surowce od innych producentów oraz Cannabis extractum normatum 10%.",
      ],
    },
    {
      question: "Jakie są metody przyjmowania medycznej marihuany?",
      answer: [
        "W zależności od rodzaju choroby można podawać pacjentom marihuanę w różnych postaciach. Dziś, marihuana medyczna jest dostępna w postaci suszu do palenia lub waporyzacji, natomiast jest kilka aptek w Polsce, które zajmują przetwarzaniem surowca w celu otrzymania innych postaci leków takich jak: kapsułki doustne, czopki, maści, żele, kremy transdermalne oraz olej THC/CBD.",
      ],
    },
    {
      question: "Czy medyczna marihuana uzależnia?",
      answer: [
        "Substancją psychoaktywną zawartą w konopiach i odpowiedzialną za ew. uzależnienie jest THC, jednak prawdopodobieństwo uzależnienia jest niewielkie. Dotychczasowe badania odnoszą się do grupy użytkowników marihuany rekreacyjnej i dowodzą, że uzależnia się od niej mniej niż 10% użytkowników. Naukowcy określają to mianem uzależnienia behawioralnego – czyli od zachowania związanego z dostarczaniem marihuany do organizmu. Chorzy przyjmujący preparaty z konopi liczą na ulgę w objawach choroby i polepszenie funkcjonowania w życiu codziennym. Jeśli zatem marihuana medyczna jest w stanie nieść im poprawę, oczywistym jest to, że nie będą chcieli rezygnować ze stosowanej terapii, jednak nie jest to równoznaczne z uzależnieniem.",
      ],
    },
    {
      question: "Co takiego zawierają konopie, że leczą?",
      answer: [
        "Konopie to rośliny zawierające w swym składzie ponad 520 związków chemicznych z różnych grup, z czego ponad 100 z nich to kannabinoidy, dzięki którym roślina zyskała swą wyjątkowość. Dotychczas najdokładniej opisane zostały: THC i CBD.",
        "THC (delta-9-tetrahydrokannabinol) – to związek posiadający silne właściwości psychoaktywne. Badania naukowe dowiodły, że poza aspektem odurzającym, THC ma właściwości przeciwbólowe, przeciwzapalne, przeciwbakteryjne, rozluźniające mięśnie, rozkurczające oskrzela oraz zmniejszające ciśnienie śródgałkowe.",
        "CBD (kannabidiol) - działa wyciszająco na układ nerwowy. Ma właściwości uspokajające, przeciwlękowe, antydepresyjne, przeciwbólowe, przeciwzapalne i przeciwdrgawkowe. Działa przeciwpsychotycznie, a więc przeciwstawnie do THC, przez co w niedużym stopniu niweluje jego psychoaktywne właściwości.",
        "Rośliny konopi zawierają dodatkowo szereg innych kannabinoidów, dzięki którym roślina zyskuje szerokie właściwości lecznicze, a także związki z innych grup takie jak flawonoidy, terpeny i wiele innych. Na konkretną i wyjątkową mieszankę tych związków jakie zawiera dana roślina ma wpływ jej genetyka, a także szereg innych czynników takich jak uprawa z jakiej pochodzi, okres wegetacji, a nawet warunki konkretnego stanowiska, na którym była hodowana.",
      ],
    },
  ],
];
