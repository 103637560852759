import styled from "@emotion/styled";

export const MobileNavbar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  & > :first-of-type {
    margin-right: 8px;
  }
`;

export const HamburgerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  height: 100vh;
  width: calc(100vw - 32px);

  background-color: white;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 16px;

  z-index: 9;
`;

export const MobileNavItem = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  padding: 8px 0px;
  margin-top: 32px;
`;

export const HamburgerFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 64px;

  justify-content: flex-end;
`;

export const SocialInfo = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  text-decoration: none;

  h3 {
    margin-left: 8px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
  }
`;
