import styled from "@emotion/styled";
import Illustration from "./illustration.svg";

export const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  position: relative;
`;

export const IntroductionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 32px;
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box;
  padding: 48px 48px 59px 48px;
  position: relative;

  @media ${(props) => props.theme.mq.tablet} {
    max-width: auto;
    padding: 0 16px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h1`
  font-size: ${(props) => props.theme.fonts.sizes.xxl};

  @media ${(props) => props.theme.mq.tablet} {
    font-size: ${(props) => props.theme.fonts.sizes.l};
    &:first-of-type {
      margin-top: 40px;
    }
  }
`;

export const HeadingCaption = styled.p`
  margin: 8px 0 24px;
  font-size: 18px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.mq.tablet} {
    flex-direction: column-reverse;
    margin-top: 20px;
  }
`;

export const PitchContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.mq.tablet} {
    justify-content: space-between;
    width: 100%;

    margin-bottom: 34px;
  }
`;

export const PitchItem = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.mq.nonMobile} {
    &:not(:first-of-type) {
      margin-left: 56px;
    }
  }

  @media ${(props) => props.theme.mq.tablet} {
    flex: 1;
    p {
      font-size: ${(props) => props.theme.fonts.sizes.xxxxs};
    }
  }
`;

export const PitchHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;

  @media ${(props) => props.theme.mq.tablet} {
    margin-bottom: 8px;
  }
`;

export const PitchHeader = styled.h3`
  font-size: ${(props) => props.theme.fonts.sizes.xs};
  margin-left: 8px;
  display: flex;
  flex-direction: row;

  @media ${(props) => props.theme.mq.tablet} {
    font-size: ${(props) => props.theme.fonts.sizes.xxs};
  }
`;

export const StyledIllustration = styled(Illustration)`
  position: absolute;
  right: 0;
  z-index: -1;

  @media ${(props) => props.theme.mq.laptopM} {
    transform: scale(0.8);
    margin-right: -40px;
  }
  @media ${(props) => props.theme.mq.laptop} {
    transform: scale(0.6);
    margin-right: -150px;
  }
  @media ${(props) => props.theme.mq.tablet} {
    position: relative;
    transform: scale(0.5);
  }
`;
