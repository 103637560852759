import { useField } from "formik";
import * as React from "react";
import CheckboxChecked from "./checkbox-checked.svg";
import {
  CheckboxContainer,
  CheckboxFieldWrapper,
  CheckboxWrapper,
  HiddenCheckbox,
  StyledCheckbox,
} from "./checkbox-field.components";

interface Props {
  fieldName: string;
  children?: React.ReactNode;
}

const CheckboxField: React.FunctionComponent<Props> = ({
  fieldName,
  children,
}) => {
  const [field, meta, helpers] = useField(fieldName);

  return (
    <CheckboxFieldWrapper>
      <CheckboxContainer>
        <CheckboxWrapper
          onClick={() => {
            helpers.setTouched(true);
            helpers.setValue(!field.value);
          }}
        >
          <HiddenCheckbox type="checkbox" />
          <StyledCheckbox
            isChecked={field.value}
            isError={meta.touched && !!meta.error}
          >
            {field.value && <CheckboxChecked />}
          </StyledCheckbox>
        </CheckboxWrapper>
        {children}
      </CheckboxContainer>
    </CheckboxFieldWrapper>
  );
};

export default CheckboxField;
