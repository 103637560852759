import styled from "@emotion/styled";

export const QuestionnaireSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;
`;

export const QuestionnaireSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;

  width: 100%;
  padding-inline: 48px;
  box-sizing: border-box;

  margin-block: 80px;

  @media ${(props) => props.theme.mq.tablet} {
    width: 100%;
    flex-direction: column;

    padding-inline: 32px;
  }
`;

export const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.6;
  margin-right: 28px;
`;

export const Heading = styled.h1`
  font-size: 64px;
  line-height: 130%;

  @media ${(props) => props.theme.mq.tablet} {
    font-size: 32px;
    line-height: 130%;
  }
`;

export const Description = styled.div`
  font-size: 18px;
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  overflow-x: hidden;
  width: 1139px;
  height: 617px;

  left: -333px;
  left: 0;
  top: -124px;
  z-index: -1;

  background: radial-gradient(
      66.55% 66.55% at 71.2% 35.87%,
      #83c6e4 0%,
      #cae6b8 47.43%,
      #ffba8b 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  opacity: 0.4;
  filter: blur(200px);
  transform: matrix(-1, 0, 0, 1, 0, 0);

  @media ${(props) => props.theme.mq.tablet} {
    display: none;
  }
`;

export const Message = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
`;
