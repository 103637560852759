import * as React from "react";
import { ErrorMessage, InputLabel } from "../input-field/input.components";
import { StyledTextarea, Wrapper } from "./textarea.components";

export interface TextareaProps
  extends React.ComponentPropsWithoutRef<"textarea"> {
  error?: string;
  label?: string;
}

export const Textarea: React.FunctionComponent<
  TextareaProps & {
    forwardedRef?: React.Ref<HTMLTextAreaElement>;
  }
> = ({ error, label, className, forwardedRef, rows = 4, ...textareaProps }) => (
  <Wrapper className={className}>
    <InputLabel>{label}</InputLabel>
    <StyledTextarea
      {...textareaProps}
      isError={!!error}
      ref={forwardedRef}
      rows={rows}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
);

export default React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => <Textarea {...props} forwardedRef={ref} />,
);
