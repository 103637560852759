import { useField } from "formik";
import * as React from "react";
import { Textarea, TextareaProps } from "./text-area";

// eslint-disable-next-line @typescript-eslint/ban-types
export type TextareaFieldProps = TextareaProps & {
  fieldName: string;
};

const TextareaField: React.FunctionComponent<TextareaFieldProps> = ({
  fieldName,
  ...textareaProps
}) => {
  const [field, meta] = useField(fieldName);

  return (
    <Textarea
      id={fieldName}
      name={fieldName}
      {...textareaProps}
      value={meta.value}
      onChange={field.onChange}
      error={meta.touched ? meta.error : undefined}
    />
  );
};

export default TextareaField;
