import Link from "next/link";
import * as React from "react";
import Logo from "../../../public/images/logo.svg";
import useIsSmallDevice from "../../../utils/hooks/use-is-small-device";
import ContactInfoContainer from "./contact-info-container";
import Facebook from "./facebook.svg";
import { FooterContainer, SocialContainer } from "./footer.components";
import Instagram from "./instagram.svg";

const Footer: React.FunctionComponent = () => {
  const isSmallDevice = useIsSmallDevice();

  return (
    <FooterContainer>
      <Logo />
      {!isSmallDevice && <ContactInfoContainer />}
      <SocialContainer>
        <Link
          href="https://www.facebook.com/konopnymed/"
          target="_blank"
          rel="noreferrer"
          passHref
        >
          <Facebook />
        </Link>
        <Link
          href="https://www.instagram.com/konopnymed/"
          target="_blank"
          rel="noreferrer"
          passHref
        >
          <Instagram />
        </Link>
      </SocialContainer>
    </FooterContainer>
  );
};

export default Footer;
