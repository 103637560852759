import styled from "@emotion/styled";

export const SelectFieldWrapper = styled.div<{ isError: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  max-width: 300px;
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const SelectDropdown = styled.select<{ isError: boolean }>`
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 4px;
  border: 1px solid
    ${({ isError, theme }) => (isError ? theme.colors.red : theme.colors.bg)};
  border-radius: 8px;

  appearance: none;
  background-color: transparent;
  &:focus {
    border-color: ${(props) => props.theme.colors.brand};
    outline: none;
  }
`;

export const DropdownIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  pointer-events: none;
  height: 16px;
  width: 16px;
`;
