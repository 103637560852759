import { Formik, FormikProps } from "formik";
import * as React from "react";
import { Divider } from "../../shared/divider";
import {
  BackgroundGradient,
  Description,
  Heading,
  HeadingSection,
  Message,
  QuestionnaireSectionContainer,
  QuestionnaireSectionWrapper,
} from "./questionnaire-section.components";
import {
  QuestionnaireFormValues,
  questionnaireInitialValues,
  questionnaireValidationSchema,
} from "./questionnaire/form";
import Questionnaire from "./questionnaire/questionnaire";

const QuestionnaireSection: React.FunctionComponent<
  Record<string, React.RefObject<HTMLDivElement>>
  // eslint-disable-next-line react/display-name
> = React.forwardRef<
  HTMLDivElement,
  Record<string, React.RefObject<HTMLDivElement>>
>((_, ref) => {
  const [message, setMessage] = React.useState("");

  const onSubmit = React.useCallback(
    async (values: QuestionnaireFormValues) => {
      try {
        const formData = new FormData();
        Object.entries(values).forEach((v) =>
          v[0] === "files"
            ? v[1].forEach((file: File) => formData.append("file", file))
            : formData.append(v[0], v[1]),
        );
        const isContinuation = values.treatmentStatus === "Kontynuacja";
        const url = `/api/${isContinuation ? "continuation" : "qualification"}`;
        const res = await fetch(url, {
          method: "POST",
          body: formData,
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          redirect: "follow",
          referrerPolicy: "no-referrer",
        });
        if (res.ok) {
          const { url } = await res.json();
          window.location.replace(url);
        } else {
          throw new Error(res.statusText);
        }
      } catch (e) {
        if (!window.navigator.onLine) {
          setMessage("Brak połączenia z internetem.");
          return;
        }
        const message = `Wystąpił błąd: ${e}`;
        console.error(e);
        setMessage(message);
      }
    },
    [],
  );

  return (
    <QuestionnaireSectionContainer ref={ref}>
      <BackgroundGradient />
      <QuestionnaireSectionWrapper>
        <HeadingSection>
          <Heading>Kwestionariusz osobowy</Heading>
          <Divider marginBlock={32} />
          <Description>
            Wypełnij kwestionariusz i sprawdź, czy kwalifikujesz się do terapii
            MM już dziś.
          </Description>
        </HeadingSection>
        <Formik
          onSubmit={onSubmit}
          validateOnBlur={false}
          initialValues={questionnaireInitialValues}
          validationSchema={questionnaireValidationSchema}
        >
          {(formProps: FormikProps<QuestionnaireFormValues>) => (
            <Questionnaire hasErrors={message.length > 0} {...formProps}>
              {message.length > 0 && <Message>{message}</Message>}
            </Questionnaire>
          )}
        </Formik>
      </QuestionnaireSectionWrapper>
    </QuestionnaireSectionContainer>
  );
});

export default QuestionnaireSection;
