import styled from "@emotion/styled";

interface StyledInputProps {
  isError?: boolean;
  fullwidth?: boolean;
}

export const StyledInput = styled.input<StyledInputProps>`
  margin-top: 0px;
  color: ${(props) => props.theme.colors.dark};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: normal;
  padding: 6px 12px;
  border-radius: 8px;
  background: transparent;

  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.red : props.theme.colors.bg};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray2};
  }
  :hover:enabled {
    border: 1px solid
      ${(props) =>
        props.isError ? props.theme.colors.red : props.theme.colors.brand};
  }
  &:focus,
  &:active {
    outline: none;
    border: 1px solid
      ${(props) =>
        props.isError ? props.theme.colors.red : props.theme.colors.brand};
    box-shadow: 0px 0px 0px 2px transparent,
      0px 0px 1px 3px rgba(63, 153, 225, 0.6);
  }
`;

export const InputWrapper = styled.div<{ fullwidth?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 100px;
  width: ${(props) => (props.fullwidth ? "100%" : "fit-content")};
`;

export const InputLabel = styled.label`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;

  color: ${(props) => props.theme.colors.navy};
  margin-bottom: 4px;
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.red};
  margin-top: 4px;
`;
