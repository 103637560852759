import styled from "@emotion/styled";

interface StyledTextareaProps {
  isError?: boolean;
}

export const StyledTextarea = styled.textarea<StyledTextareaProps>`
  margin-top: 0px;
  color: ${(props) => props.theme.colors.dark};
  font-size: 14px;
  line-height: 20px;
  letter-spacing: normal;
  padding: 8px 12px;
  border-radius: 8px;
  background: transparent;
  resize: vertical;

  min-height: 60px;
  max-height: 240px;

  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.red : props.theme.colors.bg};
  &::placeholder {
    color: ${(props) => props.theme.colors.gray2};
  }
  :hover:enabled {
    border: 1px solid
      ${(props) =>
        props.isError ? props.theme.colors.red : props.theme.colors.brand};
  }
  &:focus,
  &:active {
    outline: none;
    border: 1px solid
      ${(props) =>
        props.isError ? props.theme.colors.red : props.theme.colors.brand};
    box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 1px 3px rgba(63, 153, 225, 0.6);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: "100%";
`;
