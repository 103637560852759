import * as React from "react";
import {
  ButtonSize,
  ButtonVariant,
  ButtonWrapper,
  StyledButtonProps,
} from "./button.components";

export type ButtonProps = React.ComponentPropsWithoutRef<"button"> &
  StyledButtonProps;

const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  variant = ButtonVariant.Primary,
  size = ButtonSize.Small,
  ...props
}) => {
  return (
    <ButtonWrapper variant={variant} size={size} {...props}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;
